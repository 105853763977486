/* SPLIT VIEW FOR EMAIL and APPS
------------------------------------
*/

.split-view {
  position: relative;
  height: 100%;
  .split-list {
    @include float(left);
    width: 372px;
    background: $color-contrast-lowest;
    height: 100%;
    overflow-y: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    @include transition(all 0.5s ease);
    .list-view-fake-header {
      font-size: 12px !important;
      line-height: normal !important;
    }
    .list-refresh {
      position: absolute;
      @include right(9px);
      top: 4px;
      color: $color-hinted-a;
      z-index: 101;
      i {
        font-size: 18px;
      }
    }
    .list-view-fake-header,
    .list-view-group-header {
      background: $color-contrast-lowest;
      height: 28px;
      color: $color-contrast-medium;
      font-family: $interface-font-family;
      text-transform: capitalize;
      letter-spacing: normal;
      font-size: 12px;
      @include padding-left(12px);
      padding-top: 6px;
      width: 100%;
    }

    .item {
      height: 80px;
      list-style: none;
      position: relative;
      border-bottom: 1px solid $color-border-a;
      width: 347px;
      @include margin-left( 11px );
      cursor: pointer;
      .inline {
        width: 256px;
        @include margin-left( 13px );
        & > * {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-family: $interface-font-family;
        }
      }
      .recipients {
        padding-bottom: 2px;
        letter-spacing: -0.006em;
        color: $color-contrast-higher;
      }
      .form-check {
        @include float(left);
        clear: left;
        display: none;
        max-width: 32px;
      }
      .subject {
        font-family: $interface-font-family;
        font-size: 14px;
        color: $color-contrast-higher;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        height: 19px;
        display: -webkit-box;
        white-space: normal;
        line-height: 18px;
        letter-spacing: -0.006em;
      }
      .body {
        font-size: 14px;
        height: 22px;
        color: $color-hinted-a;
        letter-spacing: -0.006em;
        font-weight: 400;
      }
      .datetime {
        font-family: $interface-font-family;
        font-size: 12px;
        position: absolute;
        @include right(12px);
        top: 7px;
        color: $color-hinted-a;
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }

  .split-details {
    position: relative;
    overflow: auto;
    height: 100%;
    .no-result {
      bottom: 0;
      @include left( 0 );
      margin-top: -34px;
      opacity: 0.32;
      position: absolute;
      @include right( 0 );
      text-align: center;
      top: 50%;
    }
    .actions {
      height: 50px;
      @include float(left);
      li {
        list-style: none;
        position: relative;
        &:last-child:after {
          display: none;
        }
        &:after {
          content: "";
          height: 14px;
          position: absolute;
          @include right(-4px);
          top: 18px;
          width: 1px;
          background: rgba(0, 0, 0, 0.07);
        }
        a {
          font-size: 13.1px;
          color: $color-contrast-high;
          font-weight: 600;
          padding: 0 13px;
          line-height: 50px;
          white-space: nowrap;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .split-view .split-list.slideLeft {
    @include translate(-100%, 0);
  }
}
