.my-masonry-grid {
    display: flex;
    margin-left: -15px; /* gutter size offset */
    width: auto;
}
.my-masonry-grid_column {
    padding-left: 15px; /* gutter size */
    background-clip: padding-box;
}
  
/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    margin-bottom: 15px;
}

@media (max-width: 425px) {
    #feed-social {
        padding-left: 30px;
        padding-right: 30px;
    }

    .social-card.col2.masonry {
        width: 100%;
    }

    .social-card.col1.masonry {
        width: 100%;
    }
}

@media (max-width: 780px) and (min-width: 425px) {
    #feed-social {
        padding-left: 30px;
        padding-right: 30px;
    }

    .social-card.col2.masonry {
        width: 100%;
    }

    .social-card.col1.masonry {
        width: 100%;
    }
}

@media (min-width: 1023px ) {
    #feed-social {
        padding-left: 100px;
        padding-right: 100px;
    }

    .social-card.col2.masonry {
        width: 100%;
    }

    .social-card.col1.masonry {
        width: 100%;
    }
}

@media (max-width: 1024px) and (min-width: 780px) {
    #feed-social {
        padding-left: 40px;
        padding-right: 40px;
    }

    .social-card.col2.masonry {
        width: 100%;
    }

    .social-card.col1.masonry {
        width: 100%;
    }
    .social-text-heading{
        margin-left: 50px;
    }
}

/* step form tabs */

#steps-form-id-tab-home {
    opacity: 0;
    height: 1px;
}

#steps-form-id-tab-home.nav-link {
    padding: 0px !important;
}

#steps-form-id-tab-profile {
    opacity: 0;
    height: 1px;
}

#steps-form-id-tab-profile.nav-link {
    padding: 0px !important;
}

#steps-form-id-tab-contact {
    opacity: 0;
    height: 1px;
}

#steps-form-id-tab-contact.nav-link {
    padding: 0px !important;
}

#steps-form-id-tab-person {
    opacity: 0;
    height: 1px;
}

#steps-form-id-tab-person.nav-link {
    padding: 0px !important;
}

#steps-form-id-tab-status {
    opacity: 0;
    height: 1px;
}

#steps-form-id-tab-status.nav-link {
    padding: 0px !important;
}

/* step form nav */

#step-form-card > nav {
    border: 0px !important;
}

#step-form-card > nav :hover {
    cursor: default;
}

/* step form inputs */

.step-form-inputs {
    border: 0px;
    font-size: 17px;
    padding: 0px;
    margin: 0px;
}

/* step form input label */

.step-form-input-label {
    font-size: 12px;
    opacity: .55;
    font-weight: 300;
    -webkit-transition: opacity .2s ease;
    transition: opacity .2s ease;
}