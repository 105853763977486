// Theme overrides
// --------------------------------------------------
// Copyright Reserved Revox - 2017
// --------------------------------------------------

// Import Vars
@import "var";

// START Theme Overwrite
.page-sidebar .sidebar-header {
  border-bottom: transparent;
  background-color: #fff;
}

.menu-behind .header {
  background-color: #fff;
}

.quickview-wrapper .nav-tabs > li > a {
  color: #9e9ea6 !important;
}

.header {
  border-bottom: transparent;
  background-color: $color-contrast-lower;
  .bubble,
  .profile-dropdown-toggle:after {
    border-color: $color-contrast-lower;
  }
}
.header.light {
  &.bg-white {
    background-color: #fff;
  }
}
.secondary-sidebar .main-menu > li.active {
  background-color: #fff;
}

.notification-toggle {
  left: -368px;
}
.page-sidebar {
  border: 1px solid #f2f2f2;
}
body {
  &.mobile {
    .page-sidebar .sidebar-menu .menu-items {
      li {
        &.active,
        &.open {
          & > a {
            color: #000;
          }
          & > .icon-thumbnail {
            color: #000;
          }
        }
      }
    }
  }
}
.jumbotron {
  background-color: $color-contrast-lowest;
}

.breadcrumb > .breadcrumb-item.active {
  color: #000;
}

.secondary-sidebar.light {
  background-color: $color-contrast-lower;
}

.split-view .split-list .item {
  background-color: $color-contrast-lower;
}

.split-view .list-view-group-container {
  background: $color-contrast-lower;
}
.simple .secondary-sidebar .sub-menu li a .badge,
.secondary-sidebar .main-menu li a .badge {
  color: #676767 !important;
}

@media (max-width: 979px) {
  .header {
    background: #fff;
  }
}
// END Theme Overwrite


.custable .react-bootstrap-table {
  overflow-x: auto;
  scroll-behavior: smooth;
  white-space: nowrap;
}
.custable .react-bootstrap-table-pagination .col-md-6{width: 50%;}

.rounded-circle {border-radius: 50% !important;}
.cusicon{width: 28px; height: 28px; min-height: auto !important;}

.page-sidebar .sidebar-menu .menu-items li > a.detailed.active {color: #ea2c54 !important;}
.page-sidebar .sidebar-menu .menu-items li > a.detailed.active + .icon-thumbnail svg{stroke: #ea2c54 ;}

.react-bootstrap-table th.sortable {cursor: pointer; position: relative; padding: 10px 15px;}
.order-4, .react-bootstrap-table th .caret-4-desc, .react-bootstrap-table th .caret-4-asc{position: absolute; right: 5px;}
.table tbody tr td{padding: 20px 15px;}

.table.table-striped tbody tr td.row-highlight {
  background-color: #C8E6C9 !important;
}

.table.table-striped tbody tr:nth-child(2n+1) td.row-highlight {
  background-color: #C8E6C9 !important;
}

.table.table-striped tbody tr:hover td.row-highlight {
  background-color: #A5D6A7 !important;
}

.react-bootstrap-table th .order-4::before, .react-bootstrap-table th .order-4::after, 
.react-bootstrap-table th .caret-4-asc::before, .react-bootstrap-table th .caret-4-desc::before,
.react-bootstrap-table th .caret-4-asc::after, .react-bootstrap-table th .caret-4-desc::after{ left: 50%; transform: translateX(-50%);}

.card .card-body, .card .card-header{padding-left: 0; padding-right: 0;}

.w-auto {width: auto !important;}

.btn{min-height: 32px;}

.react-datepicker-popper {
  z-index: 999999999 !important;
}

.modal-header h5{ text-align: center; width: 100%; font-weight: bold; color: #000; text-transform: uppercase;}


/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * { scrollbar-width: thin; scrollbar-color:#005bab #ffffff;}

  *::-webkit-scrollbar {width: 5px;}
  *::-webkit-scrollbar-track {background: var(--primary); border-radius: 5px;}
  *::-webkit-scrollbar-thumb {background-color: var(--secondary);border-radius: 50px;border: 3px solid var(--primary);}
  ::-webkit-scrollbar-thumb:hover {  background-color: var(--tertiary);}


  .h100vh {max-height: calc(100vh - 300px);overflow-y: auto;}

.sidebar-menu .ps__rail-y {display: none;}
.sidebar-menu .scrollbar-container {padding-bottom: 50px !important;}

.h100vh .social-card, .h100vh .social-card .card-header{min-height: 80px;}

.modal-content .modal-footer {justify-content: center;}
.modal-content .modal-footer .btn {min-width: 100px;min-height: 40px; margin-top: 0px !important;}
  

.FMloaderWrap{ display: flex; min-height: 200px; align-items: center; justify-content: center; width: 100%; flex-direction: column;}
.FMloader {margin:0px auto;width: 145px;height: 100px; transform: scale(0.6);}
.FMloader span {display: block;background: #253b6e;width: 5px;height: 10%; border-radius: 14px;margin-right: 5px;float: left;margin-top: 25%;}
.FMloader span:last-child {margin-right: 0px;}
.FMloader span:nth-child(1) {animation: load 2.5s 1.4s infinite linear;}
.FMloader span:nth-child(2) {animation: load 2.5s 1.2s infinite linear;}
.FMloader span:nth-child(3) {animation: load 2.5s 1s infinite linear;}
.FMloader span:nth-child(4) {animation: load 2.5s 0.8s infinite linear;}
.FMloader span:nth-child(5) {animation: load 2.5s 0.6s infinite linear;}
.FMloader span:nth-child(6) {animation: load 2.5s 0.4s infinite linear;}
.FMloader span:nth-child(7) {animation: load 2.5s 0.2s infinite linear;}
.FMloader span:nth-child(8) {animation: load 2.5s 0s infinite linear;}
.FMloader span:nth-child(9) {animation: load 2.5s 0.2s infinite linear;}
.FMloader span:nth-child(10) {animation: load 2.5s 0.4s infinite linear;}
.FMloader span:nth-child(11) {animation: load 2.5s 0.6s infinite linear;}
.FMloader span:nth-child(12) {animation: load 2.5s 0.8s infinite linear;}
.FMloader span:nth-child(13) {animation: load 2.5s 1s infinite linear;}
.FMloader span:nth-child(14) {animation: load 2.5s 1.2s infinite linear;}
.FMloader span:nth-child(15) {animation: load 2.5s 1.4s infinite linear;}

@keyframes load {
  0% {
    background: #253b6e;
    margin-top: 25%;
    height: 10%;
  }
  50% {
    background:  #253b6e;
    height: 100%;
    margin-top: 0%;
  }
  100% {
    background: #ea2c54;
    height: 10%;
    margin-top: 25%;
  }
}


.FMnodata{ display: flex; align-items: center; justify-content: center; min-height: 200px; flex-direction: column;}
.FMnodata img{ max-width: 100px; filter: grayscale(1);} 

.mh80px{ min-height: 80px !important;}

.tollfree{ border-radius: 30px; border: 1px dashed #007be8; max-width: 85%; align-items: center; justify-content: center; margin: 0px auto; min-height: 38px; background: #ebf6ff; margin-bottom: 20px;}
.tollfree p{ margin: 0; font-weight: bold;}

.custom-dropdown-container {
  position: relative;
  display: inline-block;
}

.custom-form-control {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px; 
}

.spinner-border {
  display: block;
  width:2rem;
  height: 2rem;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
  border: 0.25em solid #007be8;
  border-right-color: transparent;
  margin: 0 auto;
}

.spinner-border-upload {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
  border: 0.15em solid white;
  border-right-color: transparent;
  margin: 0 auto;
}

@keyframes spinner-border {
  to { transform: rotate(360deg) #{"/* rtl:ignore */"}; }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .page-container .page-content-wrapper .content { padding-top: 100px !important; }
}

@media (max-width: 768px) {
  .modal-content-wrapper { display: flex; justify-content: center; align-items: center; max-height: 80vh; }
  .modal-content { max-height: 90vh; overflow-y: auto; width: 100%; max-width: 100%; margin: auto; }
}

@media (max-width: 991px) {
.container-fluid{ padding: 0 15px;}
.sidebar-menu .icon-thumbnail {margin-right: 0px;}
.page-sidebar .sidebar-menu .menu-items > li ul.sub-menu > li {padding: 0px 5px 0 36px;}
body.fixed-header .header{ padding-top: 15px; padding-bottom: 15px; height: auto;}
.page-container .page-content-wrapper .content {padding-top: 100px !important;}
}

@media (max-width: 475px) {
.titWbtn{flex-direction: column; align-items: center; justify-content: center;}
.titWbtn .export-options-container{ margin-top: 15px;}
.cusmobpad{ padding-left: 0 !important; padding-right: 0 !important;}
.mx-auto{ margin: 0px auto;}
}