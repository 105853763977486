.dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}

.dropdown-default .btn.dropdown-toggle:after {
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -2px;
}