.widget-2 > div,
.widget-6 > div {
  position: initial !important;
}

.todolist-widget .task-list {
  background: url(../../../assets/img/left-border.jpg) repeat-y calc(80%);
  width: 100%;
}

.todolist-widget .task {
  cursor: pointer;
}
.task:hover .hidden {
  display: block !important;
}
.task-list-title {
  align-items: center;
  display: flex;
}
.task-list-title,
.checkbox {
  padding: 10px 20px;
}
.todolist-widget .checkbox {
  width: 20%;
}
/* Strikethrough
	  http://codepen.io/EdenSG/pen/jPENoE
	  */
.strikethrough {
  display: inline-block;
  position: relative;
  opacity: 0.7;
}

.strikethrough:after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 1px;
  margin-top: -12px;
  background: #626262;
  transform-origin: center left;
  animation: strikethrough 1s 0.3s cubic-bezier(0.55, 0, 0.1, 1) 1;
  transition: transform 0.3s cubic-bezier(0.55, 0, 0.1, 1);
}

/* .page-container .page-content-wrapper .footer {
  position: relative !important;
} */

.executive .page-container {
  margin-top: 115px;
}

/* .page-container .page-content-wrapper .content {
  padding-bottom: 0px !important;
} */

@media (max-width: 1000px) {
  .executive .page-container {
    margin-top: 75px;
  }
}

/* Keyframes for initial animation */

@keyframes strikethrough {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}
