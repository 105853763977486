/*------------------------------------------------------------------
[14. Nesstables]
*/

.dd-handle {
  border-color: fade($color-contrast-low, 70%);
  color: $color-contrast-high;
  transition: backgroud 0.2s ease-in;
  &:hover {
    background-color: $color-contrast-lowest;
  }
}

.dark {
  .dd-handle {
    color: $color-contrast-high;
    background: $color-contrast-lower;
    &:hover {
      background-color: fade($color-contrast-lower, 60%);
    }
  }
  .dd-placeholder {
    background-color: $color-contrast-lower;
  }
}

.dd3-content {
  background: $color-contrast-lowest;
  border-color: fade($color-contrast-low, 70%);
  color: $color-contrast-high;
  &:hover {
    background-color: $color-contrast-lowest;
  }
}

.dd3-handle {
  background: transparent;
  text-indent: 9999px;
  &:before {
    font-family: "pages-icon";
    color: $color-contrast-high;
    content: "\e912";
	top: 4px;
  }
}

.dd-placeholder {
  background: $color-contrast-lower;
  border-color: fade($color-contrast-high, 35%);
}

.dd-empty {
  background-image: none;
  background: $color-contrast-lower;
  border-color: fade($color-contrast-high, 50%);
}
.dd-item > button {
  font-size: 11px;
  &:before {
    font-family: "pages-icon";
    content: "\e988";
  }
}
.dd-item > button[data-action="collapse"]:before {
  font-family: "pages-icon";
  content: "\e986";
}

/* Responsive Handlers : Nestables
------------------------------------
*/
@media only screen and (min-width: 700px) {
  .dd {
    width: 100%;
  }
  .nestable-lists {
    .dd {
      float: left;
    }
  }
}
