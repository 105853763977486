
// Radio
.form-check {
	input[type="radio"] +label {
    &:before {
      bottom: 2.5px;
      border-radius: 99px;
      @include transition(
        border 0.3s 0s cubic-bezier(0.455, 0.03, 0.215, 1.33)
      );
    }
  }
  input[type="radio"]:checked {
    & + label {
      &:before {
		border-color: $color-contrast-medium;
        border-width: 5px;
      }
    }
  }
  input[type="radio"]:hover{
	&:active:not(:checked){
	  & + label {
		  &:before{
			background-color:rgba(0, 0, 0, 0.08);
		}
		}
	}
  }	
  input[type="radio"]:focus {
    & + label {
		color: $color-contrast-higher;
	  	&:before{
			outline: none !important;
			box-shadow: 0 0 0 $focus-width $color-focus;
		}
	}
  }

  input[type="radio"] {
    opacity: 0;
    position: absolute;
    top: 3px;
    width: 16px;
    height: 16px;
  }

  input[type="radio"][disabled] {
    & + label {
		cursor: not-allowed!important;
		color: $color-contrast-high;
		opacity: 0.58;
      &:before {
		cursor: not-allowed!important;
		background: mix($color-contrast-higher, $color-contrast-lower, 04%);
	  }
    }
  }
}
  // Colors
  input[type="radio"]:checked + label:before {
	.success & {
	  border-color: $color-success;
	}
	.primary & {
	  border-color: $color-primary;
	}
	.info & {
	  border-color: $color-info;
	}
	.warning & {
	  border-color: $color-warning;
	}
	.danger & {
	  border-color: $color-danger;
	}
	.complete & {
	  border-color: $color-complete;
	}
  }

/* hide focus style if not from keyboard navigation */
.js-focus-visible .form-check input[type="radio"]:focus:not(.focus-visible) + label:before {
	box-shadow: none;
}
