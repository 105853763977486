/*------------------------------------------------------------------
[13. Treeview]
*/

span.dynatree-active a {
  color: $color-contrast-higher !important;
  background-color: transparent !important;
}

span.dynatree-selected a {
  color: $color-contrast-higher !important;
  font-style: normal;
}
ul.dynatree-container a:focus,
span.dynatree-focused a:link {
  background-color: transparent;
}
ul.dynatree-container {
  background-color: transparent;
  a:hover {
    color: $color-contrast-high;
    opacity: 0.7;
    background-color: transparent;
  }
}

ul.dynatree-container a {
  color: $color-contrast-high;
}

span.dynatree-empty,
span.dynatree-vline,
span.dynatree-connector,
span.dynatree-expander,
span.dynatree-icon,
span.dynatree-checkbox,
span.dynatree-radio,
span.dynatree-drag-helper-img,
#dynatree-drop-marker {
  height: 17px;
  position: relative;
  top: 3px;
}
