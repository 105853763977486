.headerAlign {
    left: 250px !important
}
.header-corporate {
    border-bottom: transparent;
    background-color: #fff;
    z-index: 801 !important;
  }

.header-corporate .brand {
    vertical-align: middle;
    text-align: left;
    padding-left: 30px;
    margin-right: -30px;
}