.col-md-5 .disable-hover-scale .noUi-target .noUi-base .noUi-origin .noUi-handle{
	left: 25px !important;
}
.col-lg-5 .noUi-target .noUi-base .noUi-origin .noUi-handle{
	left: 10px !important;
}
.col-md-5 .noUi-target .noUi-base .noUi-origin .noUi-handle{
	left: 10px !important;
}
.noUi-vertical{
	height: 200px;
}
.vertical-slider .noUi-connects{
	height: 200px !important;
}
.vertical-slider .noUi-connect{
	height: 200px !important;
}
.vertical-slider .noUi-target .noUi-base .noUi-origin .noUi-handle{
	left: -11px !important;
}
@media (max-width: 1180px) and (min-width:768px) {
	.col-md-5 .disable-hover-scale .noUi-target .noUi-base .noUi-origin .noUi-handle{
		left: 22px !important;
	}
}
@media (max-width: 767px) and (min-width:593px) {
	.col-md-5 .disable-hover-scale .noUi-target .noUi-base .noUi-origin .noUi-handle{
		left: 55px !important;
	}
}

@media (max-width: 592px) and (min-width:400px) {
	.col-md-5 .disable-hover-scale .noUi-target .noUi-base .noUi-origin .noUi-handle{
		left: 35px !important;
	}
}

@media (max-width: 1180px) and (min-width:992px) {
	.col-lg-5 .noUi-target .noUi-base .noUi-origin .noUi-handle{
		left: 5px !important;
	}
}
@media (max-width: 991px) and (min-width:768px) {
	.col-lg-5 .noUi-target .noUi-base .noUi-origin .noUi-handle{
		left: 25px !important;
	}
}
@media (max-width: 767px) and (min-width:640px) {
	.col-lg-5 .noUi-target .noUi-base .noUi-origin .noUi-handle{
		left: 55px !important;
	}
}
@media (max-width: 639px) and (min-width:540px) {
	.col-lg-5 .noUi-target .noUi-base .noUi-origin .noUi-handle{
		left: 45px !important;
	}
}
@media (max-width: 539px) and (min-width:440px) {
	.col-lg-5 .noUi-target .noUi-base .noUi-origin .noUi-handle{
		left: 35px !important;
	}
}
@media (max-width: 439px) and (min-width:340px) {
	.col-lg-5 .noUi-target .noUi-base .noUi-origin .noUi-handle{
		left: 25px !important;
	}
}

@media (max-width: 868px) and (min-width:768px) {
	.col-md-5 .noUi-target .noUi-base .noUi-origin .noUi-handle{
		left: 5px !important;
	}
}
@media (max-width: 767px) and (min-width:640px) {
	.col-md-5 .noUi-target .noUi-base .noUi-origin .noUi-handle{
		left: 55px !important;
	}
}
@media (max-width: 639px) and (min-width:540px) {
	.col-md-5 .noUi-target .noUi-base .noUi-origin .noUi-handle{
		left: 45px !important;
	}
}
@media (max-width: 539px) and (min-width:440px) {
	.col-md-5 .noUi-target .noUi-base .noUi-origin .noUi-handle{
		left: 35px !important;
	}
}
@media (max-width: 439px) and (min-width:340px) {
	.col-md-5 .noUi-target .noUi-base .noUi-origin .noUi-handle{
		left: 25px !important;
	}
}