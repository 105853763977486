/* Overlay Search
------------------------------------
*/
.overlay {
  position: fixed;
  @include left( 0 );
  @include right( 0 );
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.95);
  z-index: 1010;
  @include padding-left(64px);
  overflow: auto;
  .inline-block {
    display: inline-block;
  }
  .overlay-brand {
    @include margin-left(7px);
    position: relative;
    top: -1px;
  }
  & > div {
    width: 100%;
    height: 260px;
    display: block;
    overflow: hidden;
  }
  .has-results {
    overflow: visible;
  }
  .overlay-search {
    font-weight: 700;
    font-size: 77px;
    height: 100px;
    letter-spacing: -1.925px;
    line-height: 100px;
    width: calc(100% - 75px);
    @include padding-left(0 !important);
  }
  .overlay-close {
    position: absolute;
    line-height: 0px;
    padding: 0;
    @include right(20px);
    i {
      font-size: 36px;
    }
  }
}
