#sub-menu-li-one , #sub-menu-li-two {
    list-style: none !important; 
    clear: none !important;
    margin: 0px 0px 0px 23px !important;
    background-color: #ffffff !important;
    padding: 0px !important;
}

#sub-menu-li-two {
    background-color: #ffffff !important;
}