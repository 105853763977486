/*------------------------------------------------------------------
[26. Vector Map : Mapplic Plugin]
*/

.horizontal-app-menu {
  .map-controls {
    @include left(15px);
    top: 15px;
  }
}
.mapplic-container {
  width: 100%;
  background-color: transparent;
  .mapplic-fullscreen-button {
    @include left(auto);
    @include right(154px);
    bottom: 0;
    top: auto;
  }
  .mapplic-clear-button {
    visibility: hidden;
  }
}
.mapplic-tooltip:before {
  content: "Location";
  font-size: 12px;
  margin: 0;
  line-height: normal;
  opacity: 0.7;
  color: $color-contrast-high;
}
.mapplic-tooltip-close {
  opacity: 0.5;
  background: none;
  &:after {
    content: "\e966";
    font-family: "pages-icon";
    font-size: 17px;
    position: relative;
    top: -7px;
    @include right(7px);
    color: $color-contrast-high;
  }
}
.mapplic-tooltip-title {
  display: none;
}
.mapplic-tooltip-content {
  margin-top: 5px;
}
.mapplic-tooltip {
  max-width: 150px;
  padding: 8px 9px;
  border-radius: 4px;
}
.mapplic-tooltip-description {
  font-weight: bold;
  color: $color-contrast-high;
  p {
    font-size: 12px;
    color: $color-contrast-medium;
    margin-bottom: 4px;
  }
}
.mapplic-tooltip-description strong {
  color: $color-danger;
  @include margin-right(2px);
}
.map-controls {
  position: absolute;
  @include left(50px);
  top: 80px;
  z-index: 1;
  select {
    width: 240px;
  }
}
.mapplic-pin {
  background-image: url("../../../assets/img/maps/marker-master.svg");
  background-size: contain;
  &.pulse {
    background-image: url("../../../assets/img/maps/pulse-master.svg");
    &.green {
      background-image: url("../../../assets/img/maps/pulse-success.svg");
    }
    &.blue {
      background-image: url("../../../assets/img/maps/pulse-complete.svg");
    }
    &.purple {
      background-image: url("../../../assets/img/maps/pulse-primary.svg");
    }
    &.yellow {
      background-image: url("../../../assets/img/maps/pulse-warning.svg");
    }
    &.red {
      background-image: url("../../../assets/img/maps/pulse-danger.svg");
    }
  }
  &.pulse-alt {
    background-image: url("#../../../assets/img/maps/pulse-alt-master.svg");
    &.green {
      background-image: url("../../../assets/img/maps/pulse-alt-success.svg");
    }
    &.blue {
      background-image: url("../../../assets/img/maps/pulse-alt-complete.svg");
    }
    &.purple {
      background-image: url("../../../assets/img/maps/pulse-alt-primary.svg");
    }
    &.yellow {
      background-image: url("../../../assets/img/maps/pulse-alt-warning.svg");
    }
    &.red {
      background-image: url("../../../assets/img/maps/pulse-alt-danger.svg");
    }
  }
  &.marker {
    background-image: url("../../../assets/img/maps/marker-master.svg");
    &.green {
      background-image: url("../../../assets/img/maps/marker-success.svg");
    }
    &.blue {
      background-image: url("../../../assets/img/maps/marker-complete.svg");
    }
    &.purple {
      background-image: url("../../../assets/img/maps/marker-primary.svg");
    }
    &.yellow {
      background-image: url("../../../assets/img/maps/marker-warning.svg");
    }
    &.red {
      background-image: url("../../../assets/img/maps/marker-danger.svg");
    }
  }
  &.marker-alt {
    background-image: url("../../../assets/img/maps/marker-alt-master.svg");
    &.green {
      background-image: url("../../../assets/img/maps/marker-alt-success.svg");
    }
    &.blue {
      background-image: url("../../../assets//img/maps/marker-alt-complete.svg");
    }
    &.purple {
      background-image: url("../../../assets/img/maps/marker-alt-primary.svg");
    }
    &.yellow {
      background-image: url("../../../assets/img/maps/marker-alt-warning.svg");
    }
    &.red {
      background-image: url("../../../assets/img/maps/marker-alt-danger.svg");
    }
  }
}
