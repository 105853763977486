/* Page Loader
------------------------------------
*/
.pace {
  .pace-progress {
    background: $color-success;
    height: 3px;
  }
  .pace-progress-inner {
    box-shadow: none;
  }
  .pace-activity {
    -webkit-animation: none;
    animation: none;
    top: 73px;
    background: url("#{$base-img-url}/progress/progress-circle-success.svg")
      no-repeat top left;
    background-size: 100% auto;
    margin: 0 auto;
    border-width: 0;
    border-radius: 0;
    width: 28px;
    height: 40px;
    @include right(19px);
    @include left(auto);
  }
}
