.widget-2 > div,
.widget-6 > div {
  position: initial !important;
}

.pending-projects-widget .nav-tabs-simple:after {
  border: none !important;
}

.pending-projects-widget .nav-tabs-simple {
  margin-top: 20px;
}

.pending-projects-widget .nav-tabs > li > a {
  padding: 0px 5px 7px 5px;
  text-align: center;
}

.todolist-widget .task-list {
  background: url(../../../assets/img/left-border.jpg) repeat-y calc(80%);
  width: 100%;
}

.todolist-widget .task {
  cursor: pointer;
}
.task:hover .hidden {
  display: block !important;
}
.task-list-title {
  align-items: center;
  display: flex;
}
.task-list-title,
.checkbox {
  padding: 10px 20px;
}
.todolist-widget .checkbox {
  width: 20%;
}
/* Strikethrough
	http://codepen.io/EdenSG/pen/jPENoE
	*/
.strikethrough {
  display: inline-block;
  position: relative;
  opacity: 0.7;
}

.strikethrough:after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 1px;
  margin-top: -12px;
  background: #626262;
  transform-origin: center left;
  animation: strikethrough 1s 0.3s cubic-bezier(0.55, 0, 0.1, 1) 1;
  transition: transform 0.3s cubic-bezier(0.55, 0, 0.1, 1);
}

/* .page-container .page-content-wrapper .footer {
  position: relative !important;
} */

/* Keyframes for initial animation */

@keyframes strikethrough {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}

.casual .breadcrumb {
  margin-top: 40px;
}

@media (max-width: 991px) {
  .casual .breadcrumb {
    margin-top: 0px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .page-container .page-content-wrapper .content {
    padding-top: 48px !important;
  }
}

@media (max-width: 979px) {
  .casual .menu-bar > ul > li.open > ul > li > a {
    color: #9096a2;
  }
}
